.container {
    overflow: scroll;
    width: 100%;
    height: 100%;
}

.inspectionResultSwitch{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 1em;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60%;

    img{
        width: 200px;
        height: 200px;
    }
}
