.profileContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;

    .profile{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1em;
        padding: 10px;
    
        div{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
    
            span{
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                text-align: left;
            }
        }
        
        img{
            width: 37px;
            height: 37px;
            object-fit: contain;
        }
    
    }
    span{
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        text-align: center;
    }
    
    .profileFilter{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1em;
        padding: 10px;

        label{
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            text-align: left;
        }

        select{
            padding: 3px;
            border-radius: 20px;
            width: 130px;
            background: #D9DEE9;
            color:#868686;
            outline: none;
            border: none;
        }:focus{
            outline: none;  
         } 
    }
}


.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60%;

    img{
        width: 200px;
        height: 200px;
    }
}
