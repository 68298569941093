@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800&family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&family=Poppins&display=swap');
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body, #root, html {
    height: 100%;
    width: 100%;
    background-color: white;
    // overflow: hidden;
}

:root {
    --primary: #202020;
    --secondry: #ffffff;
  }

