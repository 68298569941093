.newInspection{
    background-image: url(../../img/dragDropbg.png);
    overflow-y: scroll;
    color: #ffff;
    height: 100vh;
    display: flex;
    justify-content: center;
    .Instructions{
        margin: 2em auto;
        justify-content: center;
        width: 600px;
        .instructionLogo{
            width: 100px;
            margin-left: 35px;
        }
        h4{
            color: #ffff;
            gap: 2em;
            font-family: Montserrat;
            font-size: 18px;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0.34285715222358704px;
            text-align: left;
            margin-left: 35px;
        }
        div{
            margin-top: 2em;
            ul{
                gap: 2em;
                li{
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
                    font-family: Poppins;
                    font-size: 12px;
                    letter-spacing: 0.34285715222358704px;
                    .liImg{
                        height: 30px;
                        width: 20px;
                        margin: 10px 20px 10px 0;
                    }              
                }
            }
        }
    }
    .instructionBox{
        margin: 2em auto;
        .BoxContent{
            border-radius: 20px;
            padding: 2em 2.5em;
            background-color: #ffff;
            color: #010101;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            .DragTitle{
                font-family: Poppins;
                font-size: 24px;
                font-weight: 400;
                line-height: 36px;
                letter-spacing: 0.34285715222358704px;
                text-align: center;
            }
            .DragSubTitle{
                font-family: Poppins;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.34285715222358704px;
                text-align: center;
            }
            button{
                margin-top: 1em;
                height: 44px;
                width: 280px;
                border-radius: 100px;
                background: none;
                outline: none;
                border: 1px solid #010101;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                &:hover{
                    background: #157AFF;
                    outline: none;
                    border: none;
                    color: #FFFFFF;
                }
                img{
                    
                    margin-left: 10px;
                    color: #010101;
                    height: 20px;
                    width: 15px;
                }

            }
            .CarTowing{
                img{
                    margin-top: 2em;
                    height: 150px;

                }
            }
        }
    }

}

.ImageSuccessModalContainer{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background-color: #cacaca41;

    .ImageSuccessModal{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #FFFFFF;
        width: 700px;
        height: 575px;
        padding: 10px;
        gap: 1em;
        border-radius: 40px;
        font-family: Montserrat;

        p{
            font-family: Montserrat;
            color: #010101;
            font-size: 15px;
        }
        img{
            width: 300px;
            height: 350px;
        }

        .ImageSubmit{
            border: none;
            background-color: #157AFF;
            color: white;
            padding: 10px;
            border-radius: 20px;
            height: 40px;
            width: 150px;
            text-align: center;
            cursor: pointer;
            margin-top: 20px;
        }
    }
}

.ImageModalContainer{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background-color: #cacaca41;

    .ImageModal{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: #FFFFFF;
        width: 95%;
        height: 95%;
        padding: 20px;
        gap: 1em;
        border-radius: 40px;
        font-family: Montserrat;
        box-shadow: 3px 3px rgba(105, 105, 105, 0.562);
        background-image: url(../../img/dragDropbg.png);

        .ImageError{
            font-family: Montserrat;
            color: red;
        }

        .ModalBtnContainer{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5em;

            .ImageSubmit{
                border: none;
                background-color: #157AFF;
                color: white;
                padding: 10px;
                border-radius: 20px;
                height: 40px;
                width: 150px;
                text-align: center;
                cursor: pointer;

                img{
                    width: 25px;
                    height: 25px;
                    background: none;
                }
            }

            &:focus{
                border: none;
            }
        }

        .ImageUploadedArea{
            height: 90%;
            width: 95%;
            border: 3px dashed rgb(154, 154, 154);
            border-radius: 40px;
            overflow-y: scroll;
            cursor: pointer;
            .ImageContainer{
                display: flex;
                flex-wrap: wrap; 
                justify-content: flex-start; 
                align-items: flex-start;
                gap: 10px;
                padding: 10px; 
                
                div{
                    height: 180px;
                    width: 180px;
                    position: relative;
                    overflow: hidden;
                    border-radius: 10px;
                    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
                    border: 1px solid white;
                    i{
                        position: absolute;
                        top: 7px;
                        right: 7px;
                        z-index: 2;
                        color: #FFFFFF;
                        font-size: 18px;
                        cursor: pointer;
                        background-color: rgba(0, 0, 0, 0.5);
                        border-radius: 50%;
                        padding: 5px;
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain; 
                        display: block;
                        border-radius: 10px;
                    }
                }
            }

        }

        .ImageUploadArea{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 90%;
            width: 95%;
            border: 3px dashed rgb(154, 154, 154);
            border-radius: 40px;
            overflow-y: scroll;
            cursor: pointer;

            .ImageText{

                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                i{
                    color: rgb(216, 216, 216);
                    font-size: 100px;
                }
    
                h3{
                    color: white;
                }
            }
        }
    }
}

.ImageUploadArea.DragActive {
    background-color: rgba(21, 122, 255, 0.1);
    border-color: #157AFF;
}