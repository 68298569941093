.SideMenuBar {
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    height: 103%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 6;
    .SideMenuBarContent{
        height: 103vh;
        color: #ffffff;
        background-color: #010101;
        width: 5%;
        animation: sideMenuBar 0.5s;
        .SideMenuBarContainer{
            display: flex;
            padding: 1em;
            justify-content: center;
            flex-direction: column;
            gap: 2em;
        }
        .SideMenuBarClose{
            display: flex;
            justify-content:center;
            align-items: center;
            margin-bottom: 4em;
            cursor: pointer;
        }
        .SideMenuBtn{
            display: flex;
            flex-direction: column;
            align-items: center ;
            justify-content: center;
            gap: 2em;
            div{
                display: flex;
                justify-content:center;
                height:50px;
                width: 50px;
                border-radius: 50px;
                overflow: hidden;
                img{
                    padding: 10px;
                    background-color: #464646;
                    width: 100px;
                }
                :hover{
                    background-color: #157AFF;
                    cursor: pointer;
                }
            }
        }

    }
    @keyframes sideMenuBar {
        from {width: 0%;}
        to {width: 5%;}
      }
}