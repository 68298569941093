.container{
    background-color: #F1F5FC;
    height: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: auto;

    .filterContainer{
        width: 95%;
        background-color: #FFFFFF;
        border-radius: 15px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: auto;

        .profile{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1em;
    
            div{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
    
                span{
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    text-align: left;
                }
            }
            
            img{
                width: 37px;
                height: 37px;
                object-fit: contain;
            }
    
        }
    
        .filter{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1em;
    
            div{
                display: flex;
                justify-content: center;
                align-items: flex-start;
                gap: 1em;
    
                span{
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    text-align: left;
                }
    
                .filterClear{
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: #1F7AFF;
                    border-bottom: 1px solid #1F7AFF;
                    cursor: pointer;
                }
            }
    
            img{
                width: 37px;
                height: 37px;
                object-fit: contain;
                cursor: pointer;
            }
              
        }
    }

    .info{
        width: 95%;
        max-width: 95%;
        background-color: #85BAFF80;
        border-radius: 15px;
        padding: 15px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 20px;
        gap: 4em;
        position: relative;
        overflow: auto;
        flex-shrink: 0;

        .infoimg{
            width: 90px;
            height: 70px;
        }

        .infoContent{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 1em;
        
            .headingContent{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 2em;

                p{
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17px;
                    letter-spacing: 1.5px;
                    text-align: right;
                }
            }
            
            .allData{
                display: flex;
                justify-content: center;
                align-items: center;

                .address{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 1em;
                    border-right: 1px solid #AFAFAF;
                    padding-right: 100px;
                
                    span{
                        font-family: Montserrat;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 15px;
                        letter-spacing: 1.5px;
                        text-align: left;
                    }

                    div{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 0.5em;
                    }
                }

                .carGrade{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 1em;
                    border-right: 1px solid #AFAFAF;
                    padding-right: 75px;
                    padding-left: 30px;
                
                    span{
                        font-family: Montserrat;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 17px;
                        letter-spacing: 1.5px;
                        text-align: left;
                        color: #157AFF;
                    }
                }

                .kms{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    border-right: 1px solid #AFAFAF;
                    padding-right: 75px;
                    padding-left: 30px;
                    gap: 1em;
                
                    span{
                        font-family: Montserrat;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 15px;
                        letter-spacing: 1.5px;
                        text-align: left;
                    }
                }

                .age{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 1em;
                    border-right: 1px solid #AFAFAF;
                    padding-right: 75px;
                    padding-left: 30px;
                
                    .ageTitle{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        gap: 1em;

                        span{
                            font-family: Montserrat;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 17px;
                            letter-spacing: 0.34285715222358704px;
                            text-align: left;
                        }
                    }

                    .ageValues{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        gap: 1em;

                        span{
                            font-family: Montserrat;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 17px;
                            letter-spacing: 1.5px;
                            text-align: left;
                        }
                    }
                }
            }
        }
    }

    .card{
        width: 95%;
        height: auto;
        border: 1px solid #808080;
        border-radius: 10px;
        margin-bottom: 40px;
        margin-top: 30px;
    
        .cardHeading{
            padding: 10px;
            border-bottom: 1px solid #D1D0D0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .heading1{
                font-family: Montserrat;
                color: #808080;
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 1.5px;
                text-align: left;
            }
        }
        .carData{
            border-bottom: 1px solid #D1D0D0;
            padding: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 7em;
            
            .name{
                font-family: Montserrat;
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 1.5px;
                text-align: left;
            }
            
            .time{
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 1.5px;
                text-align: left;
                color: #808080;
            }
            
            .odo{
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 1.5px;
                text-align: left;
                color: #273240;
            }
            
            .carGr{
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 1.5px;
                text-align: left;
                color: #157AFF;
            }
        }
        .buttonContainer{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1em;
            margin-top: 20px;
            margin-bottom: 20px;
        
            .minButton{
                display: flex;
                justify-content: space-around;
                align-items: center;
                width: 95%;
                height: 40px;
                background-color: #157AFF;
                border-radius: 20px;
                position: relative;
                cursor: pointer;

                p{
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17px;
                    letter-spacing: 1.5px;
                    text-align: center;
                    color: #FFFFFF;
                }

                img{
                    position: absolute;
                    right: 20px;
                    transform: rotate(180deg);
                }
            }

            .allButton{
                display: flex;
                justify-content: space-around;
                align-items: center;
                width: 95%;
                height: 40px;
                background-color: #157AFF;
                border-radius: 20px;
                position: relative;
                cursor: pointer;

                p{
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17px;
                    letter-spacing: 1.5px;
                    text-align: center;
                    color: #FFFFFF;
                }

                img{
                    position: absolute;
                    right: 20px;
                }
            }
        }
    }

    .footer{
        display: flex;
        padding: 15px;
        justify-content: flex-start;
        align-items: center;
        gap: 2em;
        background-color: #F1F5FC;
        width: 100%;
        position: fixed;
        bottom: 0;
    }

    .filterModal{
        width: 295px;
        max-height: 350px;
        height: auto;
        background-color: #FFFFFF;
        position: absolute;
        top: 152px;
        right: 63px;
        overflow: auto;

        .filterHead{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            border-bottom: 1px solid #D9D9D9;
            span{
                font-family: Montserrat;
                font-size: 20px;
                font-weight: 500;
                line-height: 24.38px;
                letter-spacing: 0.34285715222358704px;
                text-align: left;
                padding: 10px;
            }

            img{
                width: 25px;
                height: 25px;
                margin: 10px;
                cursor: pointer;
            }
        }
        .filterContentContainer{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            padding: 15px;

            .filterContent{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                width: 100%;
                gap: 0.5em;

                div{
                    width: 100%;
                }

                span{
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17.07px;
                    letter-spacing: 1.5px;
                    text-align: left;
                }

                .inputDate{
                    width: 100%;
                    border: none;
                    border-bottom: 1px solid #D9D9D9;
                    &:focus{
                        outline: none;
                    }
                }

                .checkboxContainer{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 1em;

                    .checkbox{
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 0.25em;
                    }

                    span{
                        font-family: Montserrat;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 17.07px;
                        letter-spacing: 1.5px;
                        text-align: left;
                    }
                }
            }
        }
        .filterBtn{
            width: 95%;
            padding: 10px;
            background-color: #1F7AFF;
            color: #FFFFFF;
            text-align: center;
            cursor: pointer;
            margin: 7px;
            .span{
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 400;
                line-height: 17.07px;
                letter-spacing: 1.5px;
                text-align: center;
            }
        }
    }
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60%;

    img{
        width: 100px;
        height: 100px;
    }
}