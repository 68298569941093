.DamageType{
    .background{
        height: 100vh;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
    }
    .content{
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        margin: auto;
        flex-direction: column;
        height:100% ;
        width:50% ;
        background-color: #E5E5E5;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        animation: myModal 1s;
        overflow: scroll;
        .Title{
            display : flex;
            align-items: center;
            padding: 1em 1em;
            img{
                height:2em;
                cursor: pointer;
            }
            p{
                color: #273240;
                margin-left: 1em;
                font-family: Montserrat;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 1.5px;
                text-align: left;                
            }
        }
        .mainContainer{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 1.5em;
            width: 100%;
            padding: 10px;

            .tabContainer{
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 1em;
                margin-top: 10px;
                .tabs{
                    cursor: pointer;
                    border: 1px solid black;
                    border-radius: 20px;

                    p{
                        color: #000;
                        font-family: Montserrat;
                        font-size: 10px;
                        font-weight: 400;
                        line-height: 16px;
                        letter-spacing: 1.5px;
                        text-align: center; 
                        padding: 5px 10px;
                        cursor: pointer; 
                    }
                }
            }

            .subContainer{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                width: 100%;
                gap: 1em;

                p{
                    color: #000;
                    padding: 10px;
                    font-family: Montserrat;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 1.5px;
                    text-align: left;                
                }

                .tableContainer{
                    width: 100%;
                    height: 100%;
                    overflow: scroll;
                    table{
                        border-collapse: collapse;
                        width: 100%;
                        thead{
                            text-align: center;
                            background-color: #010101;
                            width: 500px;
                            font-family: Montserrat;
                            font-size: 13px;
                            font-weight: 500;
                            line-height: 17px;
                            letter-spacing: 0.34285715222358704px;
                            tr{
                                td{
                                    padding: 0.75em 1em;
                                }
                            }
                        }
                        tbody tr{
                            padding: none;
                            &:nth-child(even){
                                background-color: #FFFFFF;
                            }
                            td{
                                color: #010101;
                                font-family: Montserrat;
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 16px;
                                letter-spacing: 1.5px;
                                text-align: center;
                                padding: 0.75em;
            
                                select{
                                    background: none;
                                    border: none;
                                    border-bottom: 1px solid #010101;
                                    min-width: 105px;
                                    max-width: 120px;
                                    width: auto;
                                    text-align: center;
            
                                    &:focus{
                                        outline: none;
                                    }
                                }
                                input{
                                    background: none;
                                    border: none;
                                    border-bottom: 1px solid #010101;
                                    font-family: Montserrat;
                                    width: auto;
                                    max-width: 90px;
                                    min-width: 60px;
                                    text-align: center;
                                    color: #010101;
                                    padding: 5px 10px;
            
                                    &:focus{
                                        outline: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @keyframes myModal {
        from {width: 0%;}
        to {width: 50%;}
      }
}