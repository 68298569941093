.GenerateLink {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    .hero {
      position: absolute;
      top: 5%;
      left: 25%;
      display: flex;
      margin: auto;
      flex-direction: column;
      height: auto;
      width: 700px;
      background-color: #ffffff;
      border-radius: 15px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      hr {
        margin: 0 0;
      }
      .CloseModal {
        position: absolute;
        right: 3%;
        top: 3%;
        height: 40px;

        img{
            height: 25px;
        }
        &:hover {
          cursor: pointer;
        }
      }
      .linkTitle {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.34285715222358704px;
        text-align: center;
        padding: 1rem;
      }
      .form {
        padding: 20px 50px;
        label {
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;
          line-height: 17px;
          letter-spacing: 0.34285715222358704px;
          text-align: center;
          padding: 3px;
          color: #010101;
        }
  
        .formControl {
          padding-top: 15px;
          .btnEmail {
            width: 100px;
            height: 35px;
            background: #010101;
            color: #ffffff;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0.34285715222358704px;
            text-align: center;
            border: none;
            border-radius: 5px 5px 0 0;
          }
          :focus {
            background: #157aff;
          }
        }
        
        label {
          font-family: Montserrat;
          font-size: 13px;
          font-weight: 400;
          line-height: 17px;
        }
        input{
          width: 600px;
          border-radius: 3px;
          padding: 5px;
          background: #D9DEE9;
          outline: none;
          border: none;
          margin-bottom: 8px;
        }
        :focus{
          outline: none;  
        } 

        Select{
          width: 600px;
          border-radius: 3px;
          padding: 5px;
          background: #D9DEE9;
          outline: none;
          border: none;
          margin-bottom: 8px;
        }

        .linkType {
          p {
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0.34285715222358704px;
            text-align: left;
            padding: 10px 0;
          }
          button {
            border-radius: 5px;
            height: 35px;
            width: 105px;
            margin-right: 10px;
            border: none;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            letter-spacing: 0.342857px;
            background-color: #dde3eb;
          }
          :focus {
            background: #157aff;
            color: #ffffff;
          }
        }
        .shareLink {
          margin: 20px 0 0 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          button {
            background: #157aff;
            color: #ffffff;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.34285715222358704px;
            text-align: center;
            border: none;
            outline: none;
            border-radius: 30px;
            height: 46px;
            width: 200px;
            padding: 10px;
            cursor: pointer;
            
            img{
              width: 25px;
              height: 25px;
              background: none;
            }
          }
          p {
            font-family: Montserrat;
            color: #010101;
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0.34285715222358704px;
            text-align: center;
            display: flex;
            color: green;
            // justify-content: center;
            // align-items: center;
            img {
              padding: 0 5px;
            }
            a {
              font-family: Montserrat;
              color: #157aff;
              text-decoration: none;
              font-weight: 600;
              font-size: 14px;
            }
          }
        }
        .LinkList {
          display: flex;
          justify-content: space-between;
          gap: 2em;
          flex-direction: column;
          margin-bottom: 5px;
          .Linkdropdown {
            display: flex;
            justify-content: space-between;
            position: relative;
            display: inline-block;
            border: 3px solid #59595991;
            margin-bottom:  10px;
            .DropdownHeader {
              background-color: transparent;
              color: #010101;
              padding: 2px;
              font-size: 16px;
              border: none;
              cursor: pointer;
              display: flex;
              justify-content: space-between;
              .Img {
                height: 20px;
                color: #010101;
              }
              .arrow {
                display: flex;
                justify-content: center;
                align-items: center;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid #ccc;
                transform: rotate(-90deg);
                transition: transform 0.3s ease-in-out;
                .down {
                  transform: rotate(180deg);
                }
              }
            }
            .DropdownList {
              background-color: #dde3eb;
              height: 70px;
              position: absolute;
              top: 100%;
              overflow-y: scroll;
              right: 0;
              color: #101010;
              // background-color: transparent;
              font-family: Montserrat;
              z-index: 1;
              list-style: none;
              padding-left: 0;
              margin-top: 6px;
              width: 100%;
              font-size: 14px;
              animation: mymove 0.5s; //onclick pai style se height ko auto ;
              .DropdownItem {
                padding: 10px 15px;
                cursor: pointer;
                height: auto;
              }
              :hover {
                color: #010101;
                background-color: #ffffff;
              }
            }
            @keyframes mymove {
              from {
                height: 0%;
              }
              to {
                height: 70px;
              }
            }
          }
        }
      }

      .radio {
        display: flex;
        gap: 0;
        .FormCheckInput {
          display: flex;
          align-items: flex-end;
          margin-left: none;
          align-self: start;
        }
      }

    }
  }

label{
  span{
    color: red;
  }
}