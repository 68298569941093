.switchBtnContainer{
    display: flex;
    padding: 20px 0;
    justify-content: center;
    div{
        border-radius: 25px;
        background-color: #010101;
        border: none;
        display: flex;
        .switchBtn{
            padding:0 15px;
            display: flex;
            align-items: center;
            background: #010101;
            border: none;
            outline: none;
            color: #FFFFFF;
            font-family: Montserrat;
            font-size: 14px;
            height: 40px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 1.5px;
            text-align: center;
            border-radius: 20px;
            transition: 0.5s ease-out;
            cursor: pointer;
            background-color: #010101;

            &:hover{
                background-color: #157aff92;
            }
        }
    }
}

.ModuleID{
    display: flex;
    justify-content: flex-start;
    gap: 0.5em;
    align-items: center;
    font-family: Montserrat;
    font-size: 14px;
    letter-spacing: 0.34285715222358704px;
    text-align: left;
    color: #010101;
    margin-top: 25px;
    margin-left: 20px;
    cursor: pointer;

    img{
        width: 15px;
    }
}

.carlevel{
    flex: 0 0 55%;
    max-height: 93vh;
    .carSvgContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .carSvgFlex{
            display: flex;
            justify-content: center;
            align-items: flex-end;
        }
        .carImg{
            height: 560px;
            width: 630px;
                .img{
                    height: auto;
                    width: auto;
                }
        }
        .carSvgFooter{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2em;
            .footerTitle{
                color: #010101;
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 600;
                line-height: 15px;
                letter-spacing: 1.5px;
                text-align: left;                        
            }
            .footermark{
                display: flex;
                flex-direction: row;
                // justify-content:center;
                align-items:center;
                gap: .5em;
                .circle1{
                    background-color: #FFB800;
                    height: 10px;
                    width: 10px;
                    border-radius:100%;
                }
                .text{
                    color: #010101;
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 15px;
                    letter-spacing: 1.5px;
                    text-align: left;   
                }
                .circle2{
                    background-color: #FF8A00;
                    height: 10px;
                    width: 10px;
                    border-radius:100%;
                }
                .circle3{
                    background-color: #FF0000;
                    height: 10px;
                    width: 10px;
                    border-radius:100%;
                }
            }
            .footerList{
                color: #010101;
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 600;
                line-height: 15px;
                letter-spacing: 1.5px;
                text-align: left;
            }
            .carSmall{
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}
// ::-webkit-scrollbar {
//     display: none;
//   }