.resultDashboard{
    background: #F1F5FC;
    max-height: 93vh;
    overflow-y: scroll;
    flex: 0 0 45%;
    .dashBoardContent{
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: #FFFFFF;
        .dashboardHeader{
            background: #101010;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 15px 10px;
            border-radius: 15px;
            margin:15px 20px 15px 20px;

            div{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 1em;

                select{
                    padding: 3px 5px;
                    border-radius: 10px;
                    background: #535350;
                    color: #e2e2e2;
                    border: none;
                }
            }

            .dashboardTitle{
                text-align: left;
                color: #FFFFFF;
                font-family: Poppins;
                font-size: 12px;
                font-weight: 600;
                line-height: 21px;
                letter-spacing: 1.5px;
                text-align: left;  
            }
            .dashboardSub{
                color: #FFFFFF;
                font-family: Montserrat;
                font-size: 11px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 1.5px;
                text-align: left;                  
            }
            .downloadBtn{
                margin-top: 22px;
                background: #157AFF;
                color: #e2e2e2;
                font-family: Montserrat;
                font-size: 10px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 1.5px;
                text-align: center;
                padding: 5px 10px;
                border-radius: 20px;
                border: none;
                cursor: pointer;

                img{
                    height: 12px;
                    width: 14px;
                }
            }
            
        }
        .buttonSection{
            gap: 1em;
            display: flex;
            flex-direction: row;
            width: auto;
            padding: 10px 0;
            margin-left: 35px;
            // overflow-x: scroll;
            .dashboardButton{
                padding:0 15px;
                display: flex;
                align-items: center;
                background: #157AFF;
                border: none;
                outline: none;
                color: #FFFFFF;
                font-family: Montserrat;
                font-size: 11px;
                height: 40px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 1.5px;
                text-align: center;
                border-radius: 20px;
                
            }
        }
    }
    .dashboardTable{
        // height: auto;
        overflow-y: scroll;
        color: #101010;
        div{
            &:nth-child(even){
                background-color: #FFFFFF;
            }
            height: auto;
            .dashboardTableBar{
                height: 60px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 500;
                line-height: 17px;
                letter-spacing: 1.5px;
                text-align: left;
                padding-left: 35px;
                padding-right: 35px;
                div{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 1em;
                    input{
                        padding: 3px 5px;
                        border-radius: 10px;
                        background: #535350;
                        color: #e2e2e2;
                        border: none;
                    }

                    select{
                        padding: 3px 5px;
                        border-radius: 10px;
                        background: #535350;
                        color: #e2e2e2;
                        border: none;
                    }

                    .submitBtn{
                        background: #157AFF;
                        color: #e2e2e2;
                        font-family: Montserrat;
                        font-size: 10px;
                        font-weight: 400;
                        line-height: 16px;
                        letter-spacing: 1.5px;
                        text-align: center;
                        padding: 5px 10px;
                        border-radius: 20px;
                        border: none;
                        cursor: pointer;
                    }
                }
            }
            
        }
    }

    .polcyModalContainer{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        z-index: 5;
        width: 100%;
        height: 100%;
        background-color: #cacaca41;

        .policyModalMain{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            background-color: #FFFFFF;
            width: 400px;
            height: 150px;
            padding: 20px;
            gap: 1em;
            border-radius: 40px;
            font-family: Montserrat;
            box-shadow: 3px 3px rgba(105, 105, 105, 0.562);
            
            input{
                width: 300px;
                padding: 5px 10px;
                background: #535350;
                border: none;
                border-radius: 20px;
                color: #e2e2e2;
                &:focus{
                    outline: none;
                }
            }

            .btnContainer{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 3em;
                .submitBtn{
                    background: #157AFF;
                    color: #e2e2e2;
                    font-family: Montserrat;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 1.5px;
                    text-align: center;
                    padding: 5px 10px;
                    border-radius: 20px;
                    border: none;
                    cursor: pointer;
                }
            }

            .loading{
                width: 100px;
            }

            .tick{
                width: 75px;
            }
        }
    }
}

.remarkModalContainer{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100vw;
    height: 100vh;
    background-color: #cacaca41;

    .remarkModal{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: #FFFFFF;
        width: 600px;
        height: 300px;
        border-radius: 50px;
        padding: 30px;
        gap: 1em;

        h4{
            font-family: Montserrat;
            line-height: 17px;
            letter-spacing: 0.34285715222358704px;
        }

        textarea{
            width: 400px;
            height: 150px;
            font-size: 20px;
            padding: 3px;
        }

        .remarkModalButtonContainer{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 6em;

            div{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 35px;
                background-color: #157AFF;
                color: #FFFFFF;
                font-family: Montserrat;
                font-size: 11px;
                font-weight: 500;
                line-height: 17px;
                letter-spacing: 1.5px;
                border-radius: 20px;
                cursor: pointer;
            }
        }
    }
}

.vinModalContainer{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100vw;
    height: 100vh;
    background-color: #cacaca41;

    .vinModal{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: #FFFFFF;
        width: 400px;
        height: 200px;
        border-radius: 50px;
        padding: 30px;
        gap: 1.5em;

        h4{
            font-family: Montserrat;
            line-height: 17px;
            letter-spacing: 0.34285715222358704px;
        }

        input{
            width: 300px;
            height: 50px;
            font-size: 15px;
            padding: 3px;
        }

        .vinModalButtonContainer{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 6em;

            div{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 35px;
                background-color: #157AFF;
                color: #FFFFFF;
                font-family: Montserrat;
                font-size: 11px;
                font-weight: 500;
                line-height: 17px;
                letter-spacing: 1.5px;
                border-radius: 20px;
                cursor: pointer;
            }
        }
    }
}

.remarkPara{
    width: 100px; 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 20px;
}