@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&family=Poppins&display=swap');


.main{
    // background-color: #010101;
    width: 100%;
    height: 100%;
    .copy-right{
        position: absolute;
        bottom: 5%;
        right:5%;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0.34285715222358704px;
        text-align: left;
        color: #FFFFFF;
    }
    nav{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100px;

        .language-button{
            background-color: #202020;
            border: unset;
        }
        .logo-module{
            display: flex;
            align-items: center;
            // justify-content: center;
            width:100%;
            padding-left: 25px ;
            img {
                width: 100px;
            }
        }
        
        .navbar-list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 2em;

            // flex-direction: column;
            .dropdown {
                position: relative;
                display: inline-block;
                .dropdown-header{
                    width: 120px;        
                    padding: 3px 15px;
                    border: 1px solid #ccc;
                    border-radius: 100px;
                    color: #FFFFFF;
                    display: flex;
                    justify-content: space-between;
                    font-family: Montserrat;
                    font-size: 12px;
                    align-items: center;
                    cursor: pointer;

                    .arrow{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        border-top: 5px solid #ccc;
                        transform: rotate(-90deg);
                        transition: transform 0.3s ease-in-out;
                        .down {
                            transform: rotate(0deg);
                        }
                    }
                }
                .dropdown-list{
                    height: 70px;
                    position: absolute;
                    top: 100%;
                    overflow-y: scroll;
                    scrollbar-width: none;
                    right: 0;
                    color: #ffffff;
                    background-color: #202020;
                    font-family: Montserrat;
                    border: 0.5px solid #ffffff;
                    border-radius: 5px;
                    z-index: 1;
                    list-style: none;
                    padding: 0;
                    margin-top: 3px;
                    width: 115px;
                    font-size: 10px;
                    animation: mymove 1s; //onclick pai style se height ko auto ;
                    .dropdown-item {
                        padding: 10px 15px;
                        cursor: pointer;
                        height: auto;
                    }
                    :hover{
                        color: #010101;
                        background-color: #ffffff;
                    }
                }
                @keyframes mymove {
                    from {height: 0%;}
                    to {height: 70px;}
                  }  
            }

            .profile-dropdown {
                position: relative;
                display: inline-block;
                margin-right: 50px;
                .profile-dropdown-header{
                    width: 130px;
                    padding: 3px 15px;
                    border: 1px solid #ccc;
                    border-radius: 100px;
                    color: #FFFFFF;
                    display: flex;
                    justify-content: space-between;
                    font-family: Montserrat;
                    font-size: 12px;
                    align-items: center;
                    cursor: pointer;
                    .mobile-icon {
                        display: none;
                      }

                    .arrow{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        border-top: 5px solid #ccc;
                        transform: rotate(-90deg);
                        transition: transform 0.3s ease-in-out;
                        .down {
                            transform: rotate(0deg);
                        }
                    }
                }
                .profile-dropdown-list{
                    height: 123px;
                    position: absolute;
                    top: 100%;
                    right: 0;
                    color: #ffffff;
                    background-color: #202020;
                    font-family: Montserrat;
                    border: 0.5px solid #ffffff;
                    border-radius: 5px;
                    z-index: 1;
                    list-style: none;
                    padding: 0;
                    margin-top: 3px;
                    width: 200px;
                    font-size: 13px;
                    li {
                        height: 40px;
                        padding: 10px;
                    }
                    // :nth-child(1){
                    //     color: #010101;
                    //     background-color: #ffffff;
                    // }
                    // :nth-child(2){
                    //     color: #010101;
                    //     background-color: #ffffff;
                    // }
                    :nth-child(3){
                        &:hover{
                            color: #010101;
                            background-color: #ffffff;
                        }
                    }
                }
                
            }
              
            p{
                border: 1px solid #FFFFFF;
                padding: 3px 15px;
                border-radius: 100px;
                margin-right: 50px;
                display:inline-block;
                align-items: center;
                color: #FFFFFF;
                background: transparent;
                font-family: Montserrat;
                font-size: 9.5px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0.34285715222358704px;
                text-align: left;

                &:hover{
                    cursor: pointer;
                }
            }
        }
    }

    .content-module{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        margin-top: 150px;
        .content-title{
            gap: 5em;
            display: flex;
            margin: auto;
            font-family: Montserrat;
            font-size: 17px;
            font-weight: 400;
            line-height: 29px;
            letter-spacing: 0.34285715222358704px;
            text-align: right;
            color: #FFFFFF;
            margin-bottom: 50px;
        }
    }
    .content-box{
        // position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4rem;
        flex-wrap: wrap;
        
        .content-item{
            width: 180px;
            height: 180px;
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 20px;
            background: linear-gradient(180deg, #FFFFFF 38.48%, #B7C4D5 129.21%);
            border-radius: 25px;
            position: relative;
            
            &:hover{
                cursor: pointer;
            }
            .hide{
                display: none;
                position: absolute;
                width: 171px;
                height: 171px;
                line-height: 22px;
                background: #157AFF;
                color: #FFFFFF;
                top: -1px;
                left: -1px;
                padding: 10px;
                border-radius: 25px;
                font-family: Poppins;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0.34285715222358704px;
                text-align: center;
                padding: 30px 10px;               
            }

            .icons {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #333333;
                height: 80px;
                width: 80px;
                border-radius: 50%;

                img {
                    object-fit: contain;
                    width: 50px;
                    height: 50px;
                }
            }

            p{
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0.34285715222358704px;
                text-align: center;
                padding: 0 ;
                margin: 0;
                margin-top: 10px;
            }
            h4{
                display: flex;
                justify-content: center;
                align-items: center;
                align-self: flex-end;
                position: absolute;
                padding: 0;
                bottom: 0;
                margin:10px 0;
                background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
                font-family: Poppins;
                font-size: 14px;
                font-weight: 600;
                line-height: 21px;
                letter-spacing: 0.34285715222358704px;
                border: 2px solid #157AFF;
                color: #157AFF;
                width: 20px;
                height: 20px;
                border-radius: 50%;
            }:hover + .hide {
                z-index: 2;
                display: block;
              
              }
            
        }
    }
}

@media only screen and (max-width: 768px){
  .main{
    nav{
        .navbar-list{
            gap: 1em !important; 
            .dropdown{
                .dropdown-header{
                    width: 100px;
                }
                .dropdown-list{
                    width: 100px;
                }
            }
            .profile-dropdown{
                margin-right: 9px;
                .profile-dropdown-header{
                    padding: 0;
                    border: none;
                    width: auto;
                    // width: auto;
                    // font-size: 30px;
                    img{
                        display: none;
                    }
                    .mobile-icon {
                        display: block;
                        font-size: 30px;
                        width: auto;
                    }
                    span{
                        display: none;
                    }
                }
            }
        }
        .logo-module{
            img{
                width: 70px;
            }
        }
    }
    .content-module{
        margin-top: 35px !important; 
    }
    .copy-right{
        padding-top: 30px ;
    }
  }
}