

body{
    overflow: hidden;
    background: var(--primary);
}

.loginNavbar{
    background: var(--primary);
}

.main{
    background: var(--primary);
    position: relative;
    width: auto;
    height: 100%;
    .copy-right{
        position: absolute;
        bottom: 5%;
        right:5%;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0.34285715222358704px;
        text-align: left;
        color: #FFFFFF;
    }
    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 241px;
        height: 141px;
        img {
            width: 100px;
        }
    }
    .login-content{ 
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--primary);
        gap: 7em;
        .login-title{
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content:center;
            gap: 0.3em;
            position: relative;
            .title{
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 800;
                font-size: 26px;
                letter-spacing: 0.342857px;
                margin: 0;
                color: var(--secondry);   
            }
            &:before{
                content:url("../../img/RectangleBefore.png");
                position: absolute;
                bottom: 12px;
                left: -100px;
            }
            &:after{
                content:url("../../img/RectangleAfter.png");
                position: absolute;
                top: 55px;
                right:  -100px;
            }
            .description{
                font-family: 'Montserrat';
                font-style: normal;
                font-size: 11px;
                letter-spacing: 0.342857px;
                color: var(--secondry);
            }
        }
        .login-section{
            width: 100%;
            display: flex;
            justify-content:center;
            align-items:center;
            color: var(--secondry);
            flex-direction: column;
            background-color: var(--primary);
            gap: 1em;
            p{
                font-family: 'Montserrat';
                font-weight: 400;
                font-size: 20px;
                line-height: 20px;
                letter-spacing: 0.342857px;
                color: var(--secondry);
            }
            .formControl {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                align-items: center;
                border-radius: 100px;
                background-color:  transparent;
                width: 300px;
                height: 0px;
                border: 1px solid var(--secondry);
                color: var(--secondary);
                padding: 15px 15px;         
                &:focus{
                    border: 1px solid #157AFF;
                    outline: none ;
                }
                &:hover{
                    border: 1px solid #157AFF;
                }
            }
        }
        .Select-type {
            position: relative;
            display: inline-block;
            .Select-header{
                width: 200px;
                padding: 3px 15px;
                border: 1px solid #ccc;
                border-radius: 100px;
                color: #FFFFFF;
                display: flex;
                justify-content: space-between;
                font-family: Montserrat;
                font-size: 12px;
                align-items: center;
                cursor: pointer;

                .arrow{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 5px solid #ccc;
                    transform: rotate(-90deg);
                    transition: transform 0.3s ease-in-out;
                    .down {
                        transform: rotate(0deg);
                    }
                }
            }
            .Select-list{
                height: 100px;
                position: absolute;
                top: 100%;
                overflow-y: scroll;
                left: 10px;
                color: #ffffff;
                background-color: rgb(38, 38, 38);
                font-family: Montserrat;
                border: 0.5px solid #ffffff;
                border-radius: 5px;
                z-index: 1;
                list-style: none;
                padding: 0;
                margin-top: 3px;
                width: 180px;
                font-size: 10px;
                scrollbar-width: none;
                animation: mySelection 1s;
                @keyframes mySelection {
                    from {height: 0px;}
                    to {height: 100px;}
                }
                .Select-item {
                    padding: 10px 15px;
                    cursor: pointer;
                    height: auto;
                }
                :hover{
                    color: #010101;
                    background-color: #ffffff;
                }
            }
  
            
            
          }
        .car-grad{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;
            height: 485px;
            width: 700px;
            border-radius: 50%;
            background-image: radial-gradient(circle,rgba(0,0,255,0.7) 21%, rgba(0,0,255,0.7) 21%, #202020 58% );
            animation: identifier 1s normal backwards;
            img{
                height: 460px;
                z-index: 0;
                margin-right: 12px;
            }
            .btn{
                position: absolute;
                top: 0;
                background: #157AFF;
                height: 65px;
                width: 65px;
                border-radius: 50%;
                color: var(--secondry);
                z-index: 2;
                font-family: Poppins;
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 0.34285715222358704px;
                text-align: center;
                border: none;
                cursor: pointer;
            }
        }
        @keyframes identifier {
            
            from  {bottom: -150%}
            to {bottom: 10%}
            
        }
            
    }
}

@media only screen and (max-width: 768px) {
    .main{
        overflow-x: hidden;
        .login-content{
            width: auto;
            .login-title{
                text-align: center;
                padding: 10px;
                .title{
                    font-size:20px;
                }&::before{
                    display: none;
                }&::after{
                    display: none;
                }
                .description{
                    font-size: 10px;
                    word-wrap: break-word;
                    // width: 500px;
                    text-align: center;
                }
            }
        }
        .copy-right {
            position: relative !important; 
            font-size: 10px !important;   
            text-align: center !important; 
            right: 0% !important;
            bottom: 2% !important;
        }
        .logo {
            width: 100% !important;
        }
    }
}

// .logo-module{
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     width:100%;
//     padding-left: 10px ;
//     img {
//         width: 100px;
//     }
// }