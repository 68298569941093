.container{
    background-color: #F1F5FC;
    height: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;

    .filterContainer{
        width: 95%;
        background-color: #FFFFFF;
        border-radius: 15px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: auto;

        .profile{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1em;
    
            div{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
    
                span{
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    text-align: left;
                }
            }
            
            img{
                width: 37px;
                height: 37px;
                object-fit: contain;
            }
    
        }
    
        .filter{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1em;
    
            div{
                display: flex;
                justify-content: center;
                align-items: flex-start;
                gap: 1em;
    
                span{
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    text-align: left;
                }
    
                .filterClear{
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: #1F7AFF;
                    border-bottom: 1px solid #1F7AFF;
                    cursor: pointer;
                }
            }

            .dashboardBtn{
                background-color: #1F7AFF;
                color: #FFFFFF;
                padding: 10px;
                border-radius: 10px;
                cursor: pointer;
                span{
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    text-align: left;
                }
            }
    
            img{
                width: 37px;
                height: 37px;
                object-fit: contain;
                cursor: pointer;
            }
              
        }
    }

    .listing{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 95%;
        gap: 3em;

        p{
            font-family: Montserrat;
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.34285715222358704px;
            text-align: left;
        }

        div{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1em;

            p{
                font-family: Montserrat;
                font-size: 20px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0.34285715222358704px;
                text-align: left;
            }
        }

    }

    .tableComponent{
        position: relative;
        overflow: auto;
        width: 95%;
        height: 65vh;
        // margin-bottom: 50px;

        table{
            width: 1500px;
            height: auto;
            border-collapse: collapse;
            empty-cells: show;

            thead{
                color: #FFFFFF;
                background-color: black;

                th{
                    padding-left: 60px;
                    padding-right: 60px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    text-align: center;
                    font-family: Montserrat;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 22px;
                    letter-spacing: 0.34285715222358704px;
                    text-align: center;
                }
            }

            tbody{
                tr:nth-child(even) {
                    background-color: #FFFFFF;
                }

                td{
                    padding-left: 10px;
                    padding-right: 10px;
                    text-align: center;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17px;
                    letter-spacing: 1.5px;
                    white-space: nowrap;
                    .vin{
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 1em;

                        img{
                            width: 45px;
                            object-fit: contain;
                        }

                        span{
                            font-weight: 600;
                            cursor: pointer;
                        }
                    }

                    .comment{
                        background-color: #FFFFFF;
                        padding: 10px;
                        border: 1px solid #1F7AFF;
                        border-radius: 10px;
                        font-family: Montserrat;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 17px;
                        letter-spacing: 1.5px;
                        text-align: center;
                        cursor: pointer;
                    }
                }

                .action{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 2em;
                    padding: 10px;

                    div{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
    }

    .footer{
        display: flex;
        padding: 15px;
        justify-content: flex-start;
        align-items: center;
        gap: 2em;
        background-color: #F1F5FC;
        width: 95%;
        // position: fixed;
        // bottom: 0;
    }

    .filterModal{
        width: 295px;
        max-height: 350px;
        height: auto;
        background-color: #FFFFFF;
        position: absolute;
        top: 152px;
        right: 63px;
        overflow: auto;

        .resetFilter{
            display: flex;
            justify-content: center;
            align-items: center;
            .resetFilterBtn{
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0px;
                text-align: left;
                color: #1F7AFF;
                border-bottom: 1px solid #1F7AFF;
                cursor: pointer;
                padding: 5px;
            }
        }

        .filterHead{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            border-bottom: 1px solid #D9D9D9;
            span{
                font-family: Montserrat;
                font-size: 20px;
                font-weight: 500;
                line-height: 24.38px;
                letter-spacing: 0.34285715222358704px;
                text-align: left;
                padding: 10px;
            }

            img{
                width: 25px;
                height: 25px;
                margin: 10px;
                cursor: pointer;
            }
        }
        .filterContentContainer{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            padding: 15px;

            .filterContent{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                width: 100%;
                gap: 0.5em;

                div{
                    width: 100%;
                }

                span{
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17.07px;
                    letter-spacing: 1.5px;
                    text-align: left;
                }

                .inputDate{
                    width: 100%;
                    border: none;
                    border-bottom: 1px solid #D9D9D9;

                    &:focus{
                        outline: none;
                    }
                }

                .checkboxContainer{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 1em;

                    .checkbox{
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 0.25em;
                    }

                    span{
                        font-family: Montserrat;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 17.07px;
                        letter-spacing: 1.5px;
                        text-align: left;
                    }
                }
            }
        }
        .filterBtn{
            width: 95%;
            padding: 10px;
            background-color: #1F7AFF;
            color: #FFFFFF;
            text-align: center;
            cursor: pointer;
            margin: 7px;
            .span{
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 400;
                line-height: 17.07px;
                letter-spacing: 1.5px;
                text-align: center;
            }
        }
    }
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60%;

    img{
        width: 100px;
        height: 100px;
    }
}

.remarkModalContainer{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100vw;
    height: 100vh;
    background-color: #cacaca41;

    .remarkModal{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: #FFFFFF;
        width: 600px;
        height: 300px;
        border-radius: 50px;
        padding: 30px;
        gap: 1em;

        h4{
            font-family: Montserrat;
            line-height: 17px;
            letter-spacing: 0.34285715222358704px;
        }

        textarea{
            width: 400px;
            height: 150px;
            font-size: 20px;
            padding: 3px;
        }

        .remarkModalButtonContainer{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 6em;

            div{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 35px;
                background-color: #157AFF;
                color: #FFFFFF;
                font-family: Montserrat;
                font-size: 11px;
                font-weight: 500;
                line-height: 17px;
                letter-spacing: 1.5px;
                border-radius: 20px;
                cursor: pointer;
            }
        }
    }
}
