.partLevelDashboard{
    background: #F1F5FC;
    width: 50%;
    height: 100%;
    .partLevelDashboardContent{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        color: #FFFFFF;
        .tableContainer{
            width: 100%;
            height: 100%;
            overflow: scroll;
            table{
                border-collapse: collapse;
                width: 100%;
                thead{
                    text-align: center;
                    background-color: #010101;
                    width: 500px;
                    font-family: Montserrat;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 17px;
                    letter-spacing: 0.34285715222358704px;
                    tr{
                        td{
                            padding: 0.75em 1em;
                        }
                    }
                }
                tbody tr{
                    padding: none;
                    &:nth-child(even){
                        background-color: #FFFFFF;
                    }
                    td{
                        color: #010101;
                        font-family: Montserrat;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 16px;
                        letter-spacing: 1.5px;
                        text-align: center;
                        padding: 0.75em;
    
                        select{
                            background: none;
                            border: none;
                            border-bottom: 1px solid #010101;
                            min-width: 105px;
                            max-width: 120px;
                            width: auto;
                            text-align: center;
    
                            &:focus{
                                outline: none;
                            }
                        }
                        input{
                            background: none;
                            border: none;
                            border-bottom: 1px solid #010101;
                            font-family: Montserrat;
                            width: auto;
                            max-width: 90px;
                            min-width: 60px;
                            text-align: center;
                            color: #010101;
                            padding: 5px 10px;
    
                            &:focus{
                                outline: none;
                            }
                        }
                    }
                }
            }
        }
        .partLevelDashboardHeader{
            background: #101010;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 15px 10px;
            border-radius: 15px;
            margin:15px 20px 15px 20px;
            width: 95%;

            div{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 1em;

                select{
                    padding: 3px 5px;
                    border-radius: 10px;
                    background: #535350;
                    color: #e2e2e2;
                    border: none;
                }
            }

            .dashboardTitle{
                text-align: left;
                color: #FFFFFF;
                font-family: Poppins;
                font-size: 12px;
                font-weight: 600;
                line-height: 21px;
                letter-spacing: 1.5px;
                text-align: left;  
            }
            .dashboardSub{
                color: #FFFFFF;
                font-family: Montserrat;
                font-size: 11px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 1.5px;
                text-align: left;                  
            }
            .downloadBtn{
                margin-top: 22px;
                background: #157AFF;
                color: #e2e2e2;
                font-family: Montserrat;
                font-size: 10px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 1.5px;
                text-align: center;
                padding: 5px 10px;
                border-radius: 20px;
                border: none;
                cursor: pointer;

                img{
                    height: 12px;
                    width: 14px;
                }
            }
        }
    }
    .DashboardCircle{
        display: flex;
        justify-content: left;
        gap: 2em;
        align-items: center;
        margin-left: 2em;
        .footerTitle{
            color: #010101;
            font-family: Montserrat;
            font-size: 12px;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 1.5px;
            text-align: left;                        
        }
        .footermark{
            gap: 1em;
            display: flex;
            flex-direction: row;
            justify-content:center;
            align-items:center;
            color: #010101;
            font-family: Montserrat;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 1.5px;
            // text-align: left;    
            .circle1{
                background-color: #FFB800;
                height: 10px;
                width: 10px;
                border-radius:100%;
            }
            .circle2{
                background-color: #FF8A00;
                height: 10px;
                width: 10px;
                border-radius:100%;
            }
            .circle3{
                background-color: #FF0000;
                height: 10px;
                width: 10px;
                border-radius:100%;
            }
        }        
        
    }
}
// ::-webkit-scrollbar {
//     display: none;
//   }

  .polcyModalContainer{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background-color: #cacaca41;

    .policyModalMain{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: #FFFFFF;
        position: inherit;
        padding: 20px;
        gap: 1em;
        border-radius: 40px;
        font-family: Montserrat;
        box-shadow: 3px 3px rgba(105, 105, 105, 0.562);

        .CloseButton{
            position: absolute;
            height: 25px;
            width: 25px;
            top: 7px;
            right: 20px;
            cursor: pointer;
        }
        
        input{
            width: 300px;
            padding: 5px 10px;
            background: #535350;
            border: none;
            border-radius: 20px;
            color: #e2e2e2;
            &:focus{
                outline: none;
            }
        }

        .btnContainer{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 3em;
            .submitBtn{
                background: #157AFF;
                color: #e2e2e2;
                font-family: Montserrat;
                font-size: 10px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 1.5px;
                text-align: center;
                padding: 5px 10px;
                border-radius: 20px;
                border: none;
                cursor: pointer;
            }
        }

        .loading{
            width: 100px;
        }

        .tick{
            width: 75px;
        }
    }
}