//navbar started

.Navbar {
    background: #010101;
    height: 65px;
    .NavbarContainer{
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        .NavbarLeft{
            display: flex;
            align-items: center;
            .NavbarLogo{
                .img{
                    height: 20px;
                    width: 30px;
                    cursor: pointer;
                }
            }
        }

        .NavbarBtn {
            display: flex;
            align-items: center;
            gap: 2em;
            .NavbarList {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 2em;
              flex-direction: column;
              .dropdown {
                position: relative;
                display: inline-block;
                .dropdownHeader {
                  width: 120px;
                  padding: 3px 15px;
                  border: 1px solid #ccc;
                  border-radius: 100px;
                  color: #ffffff;
                  display: flex;
                  justify-content: space-between;
                  font-family: Montserrat;
                  font-size: 12px;
                  align-items: center;
                  cursor: pointer;
                  .arrow {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 5px solid #ccc;
                    transform: rotate(-90deg);
                    transition: transform 0.3s ease-in-out;
                    .down {
                      transform: rotate(0deg);
                    }
                  }
                }
                .DropdownList {
                  background-color: #010101;
                  height: auto;
                  position: absolute;
                  top: 100%;
                  overflow-y: scroll;
                  right: 0;
                  color: #ffffff;
                  font-family: Montserrat;
                  border: 0.5px solid #010101;
                  border-radius: 5px;
                  z-index: 1;
                  list-style: none;
                  padding: 0;
                  margin-top: 3px;
                  width: 115px;
                  font-size: 10px;
                  scrollbar-width: none;
                  animation: mymove 1s; //onclick pai style se height ko auto ;
                  .DropdownItem {
                    padding: 10px 15px;
                    cursor: pointer;
                    height: auto;
                  }
                  :hover {
                    color: #010101;
                    background-color: #ffffff;
                  }
                }
                @keyframes mymove {
                  from {
                    height: 0%;
                  }
                  to {
                    height: auto;
                  }
                }
              }
            }

            .ProfileNavbarList {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 2em;
              flex-direction: column;
              .Profiledropdown {
                position: relative;
                display: inline-block;
                .ProfiledropdownHeader {
                  width: 200px;
                  padding: 3px 15px;
                  border: 1px solid #ccc;
                  border-radius: 100px;
                  color: #ffffff;
                  display: flex;
                  justify-content: space-between;
                  font-family: Montserrat;
                  font-size: 12px;
                  align-items: center;
                  cursor: pointer;

                  .arrow {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 5px solid #ccc;
                    transform: rotate(-90deg);
                    transition: transform 0.3s ease-in-out;
                    .down {
                      transform: rotate(0deg);
                    }
                  }
                }
                .ProfileDropdownList {
                  background-color: #010101;
                  height: 155px;
                  position: absolute;
                  top: 100%;
                  right: 0;
                  color: #ffffff;
                  font-family: Montserrat;
                  border: 0.5px solid #010101;
                  border-radius: 5px;
                  z-index: 1;
                  list-style: none;
                  padding: 0;
                  margin-top: 3px;
                  width: 200px;
                  font-size: 13px;
                  li {
                    height: 50px;
                    padding: 10px;
                  }
                  :nth-child(3){
                    &:hover{
                        color: #010101;
                        background-color: #ffffff;
                    }
                  }
                }
              }
            }
          }
    }

    
    button {
        background: transparent;
            border: 1px solid #FFFFFF;
            padding: 3px 15px;
            border-radius: 100px;
            margin-right: 10px;
            display:inline-block;
            align-items: center;
            color: #FFFFFF;
            font-family: Montserrat;
            font-size: 9.5px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0.34285715222358704px;
            text-align: left;
      }
    }

  
    .CompanyLogo{
        height: 40px;
        width: 100px;
        cursor: pointer;
        img{
            height: 35px;
            width: 100px;
        }
    }


/*ControlBar CSS*/

    .controlBar{
        background: #FFFFFF;
        padding: 0.75rem 2rem;
        .controlBarContainer{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .controlBarBackButton{
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0.34285715222358704px;
                text-align: left;
                color: #000000;
                display: flex;
                justify-content: center;
                align-items: center;
                background: none;
                border: none;
                img{
                    margin-right: 5px;
                    width: 15px;
                }
            }:hover{
                cursor: pointer;
            }
            .controlBarForm{

                label {
                    font-family: Montserrat;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 17px;
                    letter-spacing: 1.5px;
                    text-align: left;
        
                }
                input{
                    left: 940.800048828125px;
                    border-radius: 3px;
                    padding: 5px;
                    margin: 12px;
                    background: #D9DEE9;
                    outline: none;
                    border: none;
                }
                select{
                  font-family: Montserrat;
                  font-size: 10px;
                  font-weight: 400;
                  line-height: 17px;
                  letter-spacing: 1.5px;
                  text-align: left;
                  left: 940.800048828125px;
                  border-radius: 3px;
                  padding: 5px;
                  margin: 12px;
                  background: #D9DEE9;
                  outline: none;
                  border: none;
                  color:#868686

                }
                :focus{
                   outline: none;  
                } 
                .controlBarInputDate{
                    height: 25px;
                    width: 100px;
                    font-family: Montserrat;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 1.5px;
                    text-align: left;
                                    
                }
                .controlBarInputId{
                    width: 230px;
                    height: 25px;
                    font-family: Montserrat;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 1.5px;
                    text-align: left;                    
                    
                }
                .controlBarSubmitButton{
                    height: 35px;
                    border-radius: 70px;
                    border: none;
                    color: #FFFFFF;
                    background: #157AFF;
                    font-family: Montserrat;
                    font-size: 11px;
                    font-weight: 500;
                    line-height: 17px;
                    letter-spacing: 1.5px;
                    padding: 10px;
                }
            }
        }     
    }
