
.switchBtnContainer{
    display: flex;
    padding: 20px 0;
    justify-content: center;
    div{
        border-radius: 25px;
        background-color: #010101;
        border: none;
        display: flex;
        .switchBtn{
            padding:0 15px;
            display: flex;
            align-items: center;
            background: #010101;
            border: none;
            outline: none;
            color: #FFFFFF;
            font-family: Montserrat;
            font-size: 14px;
            height: 40px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 1.5px;
            text-align: center;
            border-radius: 20px;
            transition: 0.5s ease-out;
            cursor: pointer;
            background-color: #010101;

            &:hover{
                background-color: #157aff92;
            }
        }
    }
}

.ModuleID{
    display: flex;
    justify-content: flex-start;
    gap: 0.5em;
    align-items: center;
    font-family: Montserrat;
    font-size: 14px;
    letter-spacing: 0.34285715222358704px;
    text-align: left;
    color: #010101;
    margin-top: 25px;
    margin-left: 20px;
    cursor: pointer;

    img{
        width: 15px;
    }
}

.carlevel{
    width: 50%;
    .Dbtn{
        position: fixed;
        left: -90px;
        top: 440px;
        display: -webkit-flex;
        display: -moz-box;
        display: flex;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-align-items: center;
        align-items: center;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        transform: rotate(90deg);
        z-index: 100;
        gap: .1em;
        .DbtnLeft{
            width: 70px;
            font-family: Montserrat;
            font-size: 13px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 1.5px;
            text-align: center;
            color: #ffffff;
            background-color: #010101;
            font-size: 10px;
            padding: 10px 15px 12px;
            border-radius: 5px;
            border: none;
            outline: none;            
        }
        :hover{
            background-color: #157AFF;
            outline: none;
            border: none;
        }:active{
            background-color: #157AFF;
            outline: none;
            border: none;
        }
    }
    .resultSvg{
        gap: .5em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .profileUpdate{
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            .profileUpdateContent{
                display: flex;
                background-color: #E0E8F1;
                width: 95%;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                .profile{
                    font-family: Montserrat;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 12px;
                    letter-spacing: 1.5px;
                    padding: 15px 0;
                }
            }
        }

        .carSvg{
            height: 100%;
            width: 95%;
            display: flex;
            flex-direction:column;
            .carSide{
                div{
                    position: absolute;
                    bottom:23%;
                    right: 0%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                }
                .carSmallText{
                    margin-top: 1em;
                    width: 200px;
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 17px;
                    letter-spacing: 0.34285715222358704px;
                    text-align: center;
                    color: #010101;
                }
            }
            .carSvgContainer{
                display: flex;
                justify-content: center;
                align-items: center;
                margin: auto;
                flex-direction: column;
                background-color: black;
                gap: 1em;
                width: 100%;
                height: 100%;
                .carImg{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 450px;
                    cursor: pointer;
                    background-color: black;
                    position: relative;
                    .img{
                            object-fit: contain;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            cursor: pointer;
                        }
                }
            }
        }
        .footer{
            width: 95%;
            .degreeUpdater{
                background-color: #E0E8F1;
                width: 100%;
                .degree{
                    font-family: Montserrat;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 12px;
                    letter-spacing: 1.5px;
                    padding: 10px 5px;
                }
            }
        }

        .imageReelContainer{
            display: flex;
            margin: auto;
            justify-content: center;
            align-items: center;
            width: 100%;
            gap: 0.5em;
            i{
                font-size: 40px;
                color: #535350;
                cursor: pointer;
            }
            .imageReel{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 4px;
                width: 100%;
                background-color: #010101c3;
                overflow-x: scroll;
                div{
                    padding: 5px;
                    cursor: pointer;
                    img{
                        height: 50px;
                        width: 89px;
                        background-color: black;
                        object-fit: contain;
                    }
                }
            }
        }

        .slidersToScroll{
            // display: flex;
            margin: auto;
            height: 50px;
            width: 270px;
            flex-direction: row;
            overflow: scroll;
        }
        .scrollContainer{
            display: flex;
            margin: auto;
            flex-direction: row;
        }
        .slick-next {
            right: 10% ;
            z-index: 4;
            background-color: black ;
            height: 30px;
            width: 50px;
        }
        .slick-prev {
            right: 10% ;
            z-index: 4;
            background-color: black ;
            height: 30px;
            width: 50px;
        }
        .slick-arrow{
            background-color: black;
        }
    }
}


.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modalInner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px; /* Ensures spacing between arrows and image */
}

.fullscreenImage {
    max-width: 80%;
    max-height: 80%;
    border-radius: 8px;
}

.arrow {
    font-size: 62px;
    color: white;
    cursor: pointer;
    padding: 10px;
    transition: transform 0.2s ease-in-out;

    &:hover {
        transform: scale(1.1);
    }
}

.closeModal {
    position: absolute;
    top: -5px;
    right: 35px;
    color: white;
    font-size: 55px;
    cursor: pointer;
    z-index: 3;
}


// css for modal
// .modalOverlay {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-color: rgba(0, 0, 0, 0.9);
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     z-index: 1000;
// }

// .modalContent {
//     position: relative;
//     max-width: 90%;
//     max-height: 90%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

// .fullscreenImage {
//     max-width: 80%;
//     max-height: 80%;
//     border-radius: 8px;
// }

// .closeModal {
//     position: absolute;
//     top: -65px;
//     right: -40px;
//     color: white;
//     font-size: 55px;
//     cursor: pointer;
// }

// .modalArrows {
//     position: absolute;
//     width: 100%;
//     display: flex;
//     justify-content: space-between;
//     padding: 0 100px;
//     top: 50%;
//     transform: translateY(-50%);
//     z-index: 1100;
// }

// .modalArrows i {
//     color: #ffffff;
//     font-size: 62px;
//     cursor: pointer;
//     padding: 10px;
// }